@media only screen and (min-width: 768px) {

#projects {
  position: fixed;
  width: 100%;
 box-sizing: border-box;
 background: #fff;
height: 100%;

}
.title-section {
  /* height: 20%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;

}
.banner-section {
  
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0vw 10vw;
  overflow: hidden;
  background-color: #fff;
  /* border-bottom: solid 2px #EFEEF1; */
}
.header-title {
  padding: 0vw 10vw;
  align-items: center;
  align-self: center;
  /* padding: 0vw 10vw; */
}
.header {
  font-family: 'Inter';
  font-weight: 200;
  font-size: 6vw;
  margin-top: 4vw;
}
.title-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#projects .line {
  font-size: 12vw;
  color: #050505;
  margin-bottom: 40px;
}

#projects .main-subtitle {
  
  color: #8D8F9A;
  margin-bottom: 40px;

}
#projects .main-text {
  color: #8D8F9A;
  font-size: 1.6vw;
  align-self: baseline;
}

.project-list {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto auto;

  padding:  0px 10vw 60px;
  
  gap: 4%;
}
.project
 {
box-sizing: border-box;
  width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: flex-end;
 overflow: hidden;

 margin-bottom: 12vw;
}

.project-img-c {
position: relative;
  height: 65vh;
  
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
  /* border-radius: 8px; */
  /* border: 1px solid #050505; */
}
.project-img {
  top: -25%;
  object-fit: cover;
    min-width: 120%;
    height: 120%;
    position: absolute;

}

.project-text-c {
  position: relative;
  width:calc(100% -80px);
  display: flex;
  flex-direction: row;
  padding: 40px 0px;
  
  z-index: 1;
  overflow: hidden;
  /* background: #151515; */
  gap: 12%;
}


.parallax-columns {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  /* padding: 10vw 0 10vw; */
  padding-bottom: 32vw;
  justify-content: center;
background-color: #fff;


}
.parallax-column {
  padding: 0 6vw;
  transform: translate3d(0px,0px,0px);
  will-change: transform;


}
.parallax-column:nth-child(2) {
  margin-top: 20vh;


}

img {
  will-change: transform;
}

.project-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.project .col {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1;
}
#projects .arrow {
  transform: scale(.8) rotate(-90deg);
  /* flex: 1 1; */
  align-self: center;
}



.project-title {
font-family: Inter;
font-size: 1.8vw;
font-weight: 300;
margin: 0;
color: #050505;
line-height: 1.4;

}
.project-subtitle {
  font-family: Inter;
  font-size: 1.4vw;
  font-weight: 400;
  margin: 0;
  color: #050505;
  opacity: .4;
  }




}