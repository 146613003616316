@media only screen and (min-width: 768px) {
#expertise {

}

#expertise .text-row {
display: flex;
flex-direction: row;
width: 80%;
justify-content: space-between;
padding-bottom: 12vw;
padding-top: 8vw;
will-change: opacity;
}
#expertise .text-row.full {
    width: 100%;
}
#expertise .section-title {
    flex-direction: row;
    
    flex-wrap: wrap;
    /* max-width: 80%; */
    gap: 2vw;
    margin-bottom: 0;
    padding-bottom: 8vw;
}

#expertise .line-wrap {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  flex-wrap: nowrap;
    }
#expertise .line {
width: fit-content;
will-change: opacity;
}
#expertise .line-break {
   color: #252525
    }
 #expertise a {
        width: 100%;
       height: 100%;
        display: inherit;
  flex-direction:inherit;
  align-self: inherit;

  justify-content: inherit;
        

    }
}