@media only screen and (min-width: 768px) {

#projects .banner {
position: relative;
}
#project .page-section .text-c {
display: flex;
flex-direction: row;
justify-content: baseline;
gap: 12%;
  }
  #project .col-right {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  #project .col-left {
    display: flex;
    flex-direction: column;
    width: 38%;
  }
#project .page-section .title {
  font-family: Inter;
  font-weight: 300;
  font-size: 2.4vw;
  color: #050505;
  line-height: 1.4;
  padding-bottom: 60px;
  }
  #project .page-section .subtitle {
    font-family: Inter;
    font-weight: 300;
    font-size: 1.6vw;
    color: #8D8F9A;
    line-height: 1.6;
    padding-bottom: 24px;

    }
    #project .page-section .paragraphs {
      margin-bottom: 12px;
      
      }
  #project .page-section .text {
    font-family: Inter;
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 1.6;
    color: #050505;
    margin-top: 0;
    }


    #project .detail {
      display: flex;
      flex-direction: column;
    }
    #project .detail-title {
      font-family: Inter;
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 1.6;
    color: #8D8F9A;
    margin-bottom: 12px;
    }
    #project .detail-content {
      font-family: Inter;
    font-weight: 400;
    font-size: 1.6vw;
    line-height: 1.6;
    color: #050505;
    margin-bottom: 20px;
    margin: 0;
    }
}