@media only screen and (min-width: 768px) {
html, body {
  background-color: #000;
  overscroll-behavior: none;
  overflow: hidden;
}
/* html {
  overflow: hidden;
  height: 100%;
  padding-bottom: env(safe-area-inset-bottom);
}
body {
  height: 100%;
  overflow: auto;
} */

/* ONLY FOR NEW VERSON */
#project .page-section {
  position: relative;
  box-sizing: content-box;
  padding: 10vw 10vw 10vw;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
#project .title-section{
  position: relative;
    box-sizing: content-box;
    padding: 100px 10vw 100px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
  justify-content: center;
  min-height: 40vh;
  align-items: flex-start;
  background: white;
}
.main-subtitle {
  font-family: Inter;
  /* color: #959595; */
  color: #fff;
  font-weight: 300;
  font-size: 1.6vw;
  margin: 0;
  margin-bottom: 4vh;
  -webkit-font-smoothing: antialiased;
}

.main-title {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 12vh;
  -webkit-font-smoothing: antialiased;
}
 .main-title .line {
  font-family: Inter;
  color: #fff;
  font-weight: 200;
  margin: 0;

  font-size: 6vw;
  max-width: 100%;
  white-space: wrap;
  -webkit-font-smoothing: antialiased;
}
 .main-text {
  font-family: Inter;
  color: #fff;
  font-weight: 200;
  margin: 0;
  font-size: 1.6vw;
  line-height: 1.6;
  max-width: 32vw;
align-self: center;

  white-space: wrap;
  -webkit-font-smoothing: antialiased;
}
.arrow {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  border: #303030 solid 1px;
  border-radius: 60px;
  align-items: center;
  }
/* ONLY FOR NEW VERSON */

body::-webkit-scrollbar {
  display: none;

}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row {
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  gap: 5%;
}
.col {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
}

h1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}
h3 {
  font-family: "Calibre";
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}
p {
  font-family: "Calibre";
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;

}



@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreBlack.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreBold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreSemibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreMedium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreRegular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreLight.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreThin.otf');
  font-weight: 200;
  font-style: normal;
}
/* @font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-bold-webfont.woff);
  font-weight: 800;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-semibold-webfont.woff);
  font-weight: 600;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-medium-webfont.woff);
  font-weight: 500;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-regular-webfont.woff);
  font-weight: 400;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-light-webfont.woff);
  font-weight: 300;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-thin-webfont.woff);
  font-weight: 200;
} */

}