@media only screen and (max-width: 768px) {
.spinning-c {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12vw;
  height: 12vh;
}
.spinning-svg {
/* max-height: 60px; */
  /* transform: scale(.4); */
  
  width: 18vw;
  height: 18vh;
  will-change: transform;
}
}