@media only screen and (max-width: 768px) {

#projects .banner {
position: relative;
}
#projects .page-section .text-c {
display: flex;
flex-direction: column;

  }
#project .page-section .title {
  font-family: Inter;
  font-weight: 300;
  font-size: 28px;
  color: #050505;
  line-height: 1.4;
  padding-bottom: 60px;
  }
  #project .page-section .subtitle {
    font-family: Inter;
    font-weight: 300;
    font-size: 16px;
    color: #8D8F9A;
    line-height: 1.6;
    padding-bottom: 24px;
    }
    #project .page-section .paragraphs {
      margin-bottom: 12px;
      }
  #project .page-section .text {
    font-family: Inter;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6;
    color: #050505;
    margin-bottom: 12px;
    }


    #project .detail {
      display: flex;
      flex-direction: column;
    }
    #project .detail-title {
      font-family: Inter;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6;
    color: #8D8F9A;
    margin-bottom: 12px;
    }
    #project .detail-content {
      font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.6;
    color: #050505;
    margin-bottom: 20px;
    margin: 0;
    }
}