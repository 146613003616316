@media only screen and (max-width: 768px) {

#navigation {
  position: fixed;
  width: 100%;
  height: 90px;
  /* background: red; */
  z-index: 3;
  /* mix-blend-mode: difference; */
}


.nav-wrap {
  position: relative;
  display: flex;
  flex-direction:row;
  padding: 0 6vw;
  height: 100%;
  align-items: center;
  /* float: right; */
  justify-content: space-between;

  padding-top: 24px;
  z-index: 4;
}

.nav-right{
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.nav-left{
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
#back-btn {
  transform: rotate(90deg);
}
.back-btn-inner {
  opacity: 0;
}


.leftNav {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;

}
.rightNav {
  position: relative;
  height: 100%;
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#logo {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  font-family: Futura;
  /* text-transform: uppercase; */
  color: white;
  will-change: opacity;
  line-height: 1;
  align-self: center;

padding-bottom: 6px;
  /* REMOVE WHEN SPRINGS WORK */
  opacity: 0;


}
.back-btn {
  position: absolute;
  font-size: 22px;
  font-weight: 300;
  /* text-transform: uppercase; */
  color: white;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.menu-btn {
  position: relative;
  color: white;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
 
}
.navItem {
  font-size: 16px;
  font-weight: 300;
  color: white;
  
}
:link {
  text-decoration: none;
}
.hamburger-react {
 will-change: transform, background;
}
.hamburger-react div {
  
  height: 1px !important;
  width: 32px !important;
  left: 8px !important;
  will-change: transform, background;
}
.hamburger-react div div {
  
 left: 0;
 will-change: transform, background;
}




.nav-active {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  top:0;
  left:0;
  background: #fff;
  will-change: transform, background;
  display: flex;
  flex-direction: column;
z-index: 1;
 overflow: hidden;

}
.nav-inner-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10% 10% 0%;
  box-sizing: border-box;
  transform: translateY(0px);
  
  display: flex;
  flex-direction: column;
justify-content: space-between;
will-change: transform, height;
  /* gap: 8vh; */
}

.nav-section {
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
gap: 10px;
flex-shrink: 1;
}
.nav-section :nth-last-child(1) > .nav-link-title {
  /* padding-top: 20px; */
  /* padding-bottom: 40px; */
  border: none !important;
}

.nav-link {
  box-sizing: border-box;
  /* display: flex; */
  opacity: 0;
  
  transform: translateY(0px);
 will-change: transform, opacity;

}

.nav-subtitle {
 

  font-size: 16px;
    font-weight: 500;
    color: #8D8F9A;
    margin: 0;
    padding-bottom: 8px;
    
}

.nav-link-title {

    font-weight: 300;
    color: #050505;
    margin: 0;
    text-transform: capitalize;

}
.nav-link-title.sm {

  font-size: 18px;
  padding-top: 2.2vh;
  padding-bottom: 1vh;
}

.nav-link-title.md {

  font-size: 20px;
  padding-top: 2.2vh;
  padding-bottom: 1vh;
}
.nav-link-title.lg {

  /* font-size: 24px; */
  font-size: 38px;
  padding-top: 2.4vh;
  padding-bottom: 2.4vh;
  /* border-bottom: 1px solid #f4f4f4 ; */
  
  
}
.nav-footer {
position: relative;
width: 100%;
bottom: 0;
padding: 50px 10vw 50px;
margin-left: -10vw;
border-top: #e1e1e1 1px solid ;
opacity: 0;
will-change: opacity, transform;

}
.nav-footer .nav-link-title {
  font-weight: 300;
  color: #050505;
  margin: 0;
  padding: 0;
  text-transform: lowercase;

}


}