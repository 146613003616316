@media only screen and (min-width: 768px) {
#process {
background: #101010;
}

/* #process .section-head {
padding-top: 8vw;
padding-bottom: 6vw;
} */
#process .section-title {
  margin:0;
  }
#process .row {
  gap: 0;
  justify-content: space-between;
  align-items: center;
}
#process .line {
  width: 100%;
}


#process .text-row {
  width: 100%;
}
#process .spinning-svg {
  /* max-height: 60px; */
    transform: scale(2.5);
    
    height: 60vh;
    width: 30%;
    will-change: transform;
    align-self: center;
  }
  .padding {
    padding-top: 12vh;
  }

  .parallax-columns {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    /* padding: 10vw 0 10vw; */
    padding-bottom: 12vw;

  }


  .parallax-column {
    padding: 0 6vw;
    transform: translate3d(0px,0px,0px);
    will-change: transform;


  }
  /* .parallax-column:nth-child(2) {
    margin-top: 12vh;
 

  } */
  .parallax-column-card{
    width: 100%;
    max-width: 100%;
    padding-bottom: 24vh;
   
  }
  .card-img-c {
    position: relative;
    overflow: hidden;
    object-fit: cover;
    height: 65vh;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .card-img {
    /* margin-top: -100%; */
    width: 100%;
    
    /* width: 160%; */
    /* max-width: 100%; */
    position: absolute;
    background: #252525;
  }
  .card-caption {
    display: flex;
    flex-direction: row;
    padding-top: 3vw;
    gap: 12%;
    align-items: center;
  }
  .caption-text {
    font-size: 1.2vw;
    line-height: 1.5;
    margin: 0;
    color: #505050;
    -webkit-font-smoothing: antialiased;
  }
  .caption-line {
    width: 40%;
    height: 1px;
    background: #252525;

  }

  .card-text-c {
    
    margin: 2vw 0;
  }
  .card-title {

    font-family: Inter;
    font-size: 2.4vw;
    color: #fff;
    font-weight: 300;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
  .card-text {
    font-family: Inter;
  color: #fff;
  font-weight: 200;
  margin: 0;
  font-size: 1.2vw;
  line-height: 1.6;
  max-width: 32vw;
align-self: center;

  white-space: wrap;
  -webkit-font-smoothing: antialiased;
  }
}