#loader {
  position: absolute;
  width: 100%;
  height: 100%;
}
#loader .main-c {
    position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loader .img-c {
}
.main-c .text-c {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
#loader .letter {
    color: white;
    font-size: 128px;
    font-weight: 300;
}
