@media only screen and (max-width: 768px) {
  #landing-mobile {
    position: relative;
    padding-top: 90px;
    background: #fff;
    display: flex;
    flex-direction: column;
  }
  #landing-mobile .content-block {
    display: flex;
    flex-direction: column;
    padding: 26px 32px;
  }
  #landing-mobile .content-block .col {
    gap: 32px;
  }
  #landing-mobile .intro-header-c {
    box-sizing: border-box;
    /* padding: 32px 0; */
  }
  #landing-mobile .intro-header {
    font-size: 48px;
    font-weight: 400;
    color: #161618;
    margin: 0;
    line-height: 56px;
  }
  #landing-mobile .intro-header:nth-child(2) {
    color: #2977F3;
  }
  #landing-mobile .intro-text {
    font-size: 18px;
    font-weight: 400;
    color: #707070;
    margin: 0;
    line-height: 24px;
  }
  /* Buttons */

  #landing-mobile .buttons-c {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
  }
  #landing-mobile .button {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 22px;
    gap: 8px;

    box-sizing: border-box;

    border-radius: 6px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    width: fit-content;
  }
  #landing-mobile .button.black {
    background: #161618;
    color: white;
  }
  #landing-mobile .button.white {
    background: #ffffff;
    color: #161618;
    border: 1px solid #e4e4e4;
  }
  #landing-mobile .dark-block .button {
    background: #252528;
    color: #fff;
    /* border: 1px solid #e4e4e4; */
  }

  /* Details */

  #landing-mobile .detail-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }
  #landing-mobile .detail {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  #landing-mobile .detail-text {
    font-size: 32px;
    font-weight: 400;
    line-height: 24px;
    color: #2977F3;
    margin: 0;
  }
  #landing-mobile .detail-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #707070;
  }

/* DARK MODE */
#landing-mobile .dark-block {
  background: #161618;
}
#landing-mobile .title-c {
  display: flex;
  flex-direction: column;
  /* padding-bottom: 32px; */
  gap: 8px;
  
}
#landing-mobile .title {
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  padding: 8px 0;
  
  
}
#landing-mobile .subtitle {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}
#landing-mobile .dark-block .title {
  color: #ffffff;
}
#landing-mobile .dark-block .subtitle {
  color: #2977F3;
}


/* Color Block */
#landing-mobile .color-block {
  background: #2977F3;
}
#landing-mobile .color-block .title {
  color: #fff;
  max-width: 60%;
}
#landing-mobile .color-block .title-icon {
  color: #1e1e1e;
}

/* Slider */

#landing-mobile .slider {
  box-sizing: border-box;
  overflow-x: scroll;
  margin: 0 -32px;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}
.slider::-webkit-scrollbar {
  display: none;
}
#landing-mobile .slider-rail {
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 32px;
}


/* Text */

#landing-mobile .text-c-row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  /* padding: 16px 0; */
}
#landing-mobile .text {
  font-size: 18px;
  color: #fff;
  margin-top: 0;
}
#landing-mobile a .text {
  color: #2977F3;
}



}
