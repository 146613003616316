@media only screen and (max-width: 768px) {

.main-subtitle {
  font-family: Inter;
  /* color: #959595; */
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
}

/* #logo.main-subtitle {
  font-size: 32px;
} */
.main-title {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 60px; */
  -webkit-font-smoothing: antialiased;
}
.main-title .line {
  font-family: Inter;
  color: #fff;
  font-weight: 200;
 

  font-size: 32px;
  max-width: 100%;
  white-space: wrap;
  -webkit-font-smoothing: antialiased;

}






.reveal {
  opacity: 0;
}
/* .landing .main-title .line {
  font-size: 38px;
  
} */
.main-c .row {
  flex-direction: column;
  gap: 40px;
  margin-top: 0px;
  /* padding-bottom: 40px; */
  
}
.page-section .main-text {
  font-family: Inter;
  color: #e1e1e1;
  font-weight: 300;
  margin: 0;
  font-size: 18px;
  line-height: 1.6;
  max-width: 100%;
/* align-self: center; */
  white-space: wrap;
  -webkit-font-smoothing: antialiased;

}
.page-section .text{
  position: relative;
  font-family: Inter;
  color: #e1e1e1;
  font-weight: 300;
  margin: 0;
  font-size: 18px;
  line-height: 1.6;
  max-width: 100%;
  white-space: wrap;
  -webkit-font-smoothing: antialiased;

}
.landing .main-text {
  padding-top:60px;

}

#about .details {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;

}
#about .detail {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;

}
#about .detail-c {

}
.detail-subtitle{
  font-family: Inter;
  color: #fff;
  font-weight: 300;
  margin: 0;
  font-size: 16px;
  opacity: .4;
  margin-bottom: 2vh;
}
.detail-text{
  font-family: Inter;
  color: #fff;
  font-weight: 300;
  margin: 0;
  font-size:18px;

}
.landing .badge-wrap {

  display: flex;
  flex-direction: row;
  justify-content: baseline;
  gap: 10vw;
  padding-top:30px;
  padding-bottom:20px;
}
.landing .badge {
  padding-left: 2px;
  /* float:left;
  transform: scale(.4); */
 height: 24px;
}

}