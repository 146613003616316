@media only screen and (max-width: 768px) {
  html {
    /* overflow: hidden; */
    height: 100%;
    padding-bottom: env(safe-area-inset-bottom);
  }
  body {
    /* height: 200vh; */
    /* overflow-y: scroll; */
    padding-bottom: env(safe-area-inset-bottom);
  }
  
}
body::-webkit-scrollbar {
  display: none;

}
#screen {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  top:0;
  left:0;
  background: #050505;
  will-change: transform, background;
  
z-index: 1;
}
.page-section {
  position: relative;
  box-sizing: content-box;
  padding: 80px 10vw 60px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.spacer {
  width: 100%;
  padding-top: 60px;
}
#navvisible{
  position: relative;
  height: 0px;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row {
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  gap: 5%;
}
.col {
  width: -webkit-fill-available;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;

}

ul {
  list-style-type: none;
}


h1 {
  font-size: 48px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}
h2 {
  font-size: 32px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
}
h3 {
  font-family: "Calibre";
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
}
p {
  font-family: "Calibre";
  font-size: 16px;
  /* line-height: 1.6em; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  line-height: 30px !important;
  
}





.arrow {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  border: #303030 solid 1px;
  border-radius: 60px;
  align-items: center;
  }
  .arrow svg{
    transform: scale(.9);
    }
  
    .btn-wrap {

      width: 100%;
      padding: 80px 0 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 20px;
    }
    .btn {
      height: 60px;
      box-sizing: content-box;
    width: min-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: #303030 solid 1px;
      border-radius: 60px;
      padding: 0 28px 0;
      
    }
    .btn.dark {

      background: #050505;
    }

  .btn-text {
    color: white;
  width: max-content;
  font-weight: 300;
  }
  .btn.dark .btn-text {
  font-weight: 400;
  }
  .btn .arrow {
    border: none;
    transform: rotate(-90deg);
    margin-right: -16px;
  }


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-bold-webfont/basiercircle-bold-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-bolditalic-webfont/basiercircle-bolditalic-webfont.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-medium-webfont/basiercircle-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-mediumitalic-webfont/basiercircle-mediumitalic-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-regular-webfont/basiercircle-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-regularitalic-webfont/basiercircle-regularitalic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibold-webfont/basiercircle-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'Basier';
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.eot');
  src: url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.woff2') format('woff2'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.woff') format('woff'),
       url('../../Assets/fonts/Basier/Basier-Circle-semibolditalic-webfont/basiercircle-semibolditalic-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;

}



@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreBlack.otf');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreBold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreSemibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreMedium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreRegular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreLight.otf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src: url('../../Assets/fonts/calibre/CalibreThin.otf');
  font-weight: 200;
  font-style: normal;
}
/* @font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-bold-webfont.woff);
  font-weight: 800;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-semibold-webfont.woff);
  font-weight: 600;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-medium-webfont.woff);
  font-weight: 500;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-regular-webfont.woff);
  font-weight: 400;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-light-webfont.woff);
  font-weight: 300;
}
@font-face {
  font-family: 'Archia';
  src: url(../../Assets/fonts/Archia_Font_Web/archia-thin-webfont.woff);
  font-weight: 200;
} */

