.wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    padding-bottom: env(safe-area-inset-bottom);
    
}

@media only screen and (max-width: 768px) {
    .wrapper {
        position: relative;
        box-sizing: border-box;
        /* overflow-y: scroll;
        overflow-x: hidden; */
        height: unset;
        width: unset;
        /* overscroll-behavior: none; */
    }
    .wrapper::-webkit-scrollbar {
        display: none;
      }
  }



