@media only screen and (max-width: 768px) {

  #list {
background: white;
    }
.list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
  }

  .list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    align-items: flex-start;
    transform: translateY(0px);
    opacity: 0;
    will-change: opacity, transform;
    }

.circle-num {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: #e1e1e1 solid 1.2px;
  border-radius: 100%;
  font-size: 16px;
}
#list .list-item .text {
  font-weight: 400;
margin: 0;
flex: 1;

}




}