@media only screen and (min-width: 768px) {
#contact {
  /* padding-top: 8vw;
  padding-bottom: 8vw; */
  background: #111315;
}
.contact-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  width: 100%;
  /* border: 1px solid #3C3E40; */
  flex-direction: row;
  justify-content: space-between;
}
.contact-card {
  box-sizing: border-box;
  padding: 4vw 0 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* max-width: 26%; */
  border-top: 1px solid #3C3E40;
  border-right: 1px solid #3C3E40;
  border-left: 1px solid #3C3E40;
  border-bottom: 1px solid #3C3E40;
}
.contact-card:nth-child(-n +2){
  /* border-right: none; */
  /* border-bottom: none; */
}
.contact-card:nth-child(odd){
  /* border-bottom: none; */
  /* border-left: none; */
  
}
.contact-card:nth-child(-n+3) {
  /* border-top: none; */
  
  }
  .contact-card:first-child,
  .contact-card:nth-child(n) {
  border-left: none;
  border-top: none;
    /* border-right: none; */
  }
  .contact-card:nth-child(2n) {
  /* border-left: none; */
  border-top: none;
  border-right: none;

  }
  
  .contact-card:nth-child(3n) {
  border-left: none;
  border-top: none;
  border-bottom: none;
  }
.contact-card .card-title {
  width: 100%;
  margin: 26px 0;
}
.contact-card .card-text {
  width: 100%;
}
.card-icon {
  height: 60px;
  max-width: 20%;
}
a {
  width: 100%;
}
  }