/* #about {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  background-color: #050505;
} */
@media only screen and (max-width: 768px) {
#about {
  position: relative;
  box-sizing: border-box;
  z-index: 2;
  background-color: #050505;
 overflow: hidden;

  /* perspective: 800px; */
  overscroll-behavior-y: none !important;
  
}
#about::-webkit-scrollbar {
  display: none;

}


/* PAGE */

#about .page-c {
  box-sizing: content-box;
  /* padding-left: 12vw;
  padding-right: 12vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding-top: 80px;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform;
}
/* #about .page-c {
  padding-bottom: 80px;
} */







#about .left-panel {
  position: relative;
  min-width: 15%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  flex: 0.5;
}
#about .right-panel {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  background: #252525;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}





.page-section .section-title {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}


#about h3 {
  font-family: Inter;
  color: white;
  font-size: 32px;
  margin: 0;
  font-weight: 200;
  white-space: wrap;
  max-width: 100%;
  -webkit-font-smoothing: antialiased;
}

.section-text-c {
  display: flex;
  flex-direction: column;
}
.text-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  will-change: opacity;
  gap: 4vw;
  }

.line {
  margin: 0;
  line-height: 1.4;
}

.button {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 80%;
  max-width: 240px;
  opacity: .5;
  margin: -30px 0 0 -30px;
  justify-content: space-between;
  padding: 30px 72px 30px 30px;
}
#about .button-text {
  font-family: Inter;
  color: #fff;
  font-weight: 300;
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
align-self: center;


  white-space: wrap;
  -webkit-font-smoothing: antialiased;
  padding-right: 12px;
}
#about .button-svg {
  transform: scale(.6);
  flex: 1;
}
}