@media only screen and (min-width: 768px) {

#navigation {
  position: fixed;
  width: 100%;
  height: 130px;
  /* background: red; */
  z-index: 3;
  /* mix-blend-mode: difference; */

}


.nav-wrap {
  position: relative;
  display: flex;
  flex-direction:row;
  padding: 0 6vw;
  height: 100%;
  align-items: center;
  float: right;
  gap: 12px;
  padding-top: 24px;
  z-index: 4;
}


.navWrap {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  height: 100%;
  padding: 0 32px;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.leftNav {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;

}
.rightNav {
  position: relative;
  height: 100%;
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#logo {
  position: relative;
  font-size: 24px;
  font-weight: 400;
  font-family: Futura;
  /* text-transform: uppercase; */
  color: white;
  will-change: opacity;
  line-height: 1;
  align-self: center;

padding-bottom: 6px;
  /* REMOVE WHEN SPRINGS WORK */
  opacity: 0;


}
.back-btn {
  position: absolute;
  font-size: 22px;
  font-weight: 300;
  /* text-transform: uppercase; */
  color: white;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.menu-btn {
  position: relative;
  color: white;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
 
}
.navItem {
  font-size: 16px;
  font-weight: 300;
  color: white;
  
}
:link {
  text-decoration: none;
}
.hamburger-react {
will-change: background, transform;
  
}
.hamburger-react div {
  
  height: 1px !important;
  width: 32px !important;
  left: 8px !important;
}
.hamburger-react div div {
  
 left: 0;
}




.nav-active {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  top:0;
  left:0;
  /* background: #404040; */
  will-change: transform, height;
  display: flex;
  flex-direction: column;
z-index: 1;
 overflow: hidden;

}
.nav-inner-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10vw 0 10vw;
  box-sizing: border-box;
  transform: translateY(0px);
  will-change: transform;
  display: flex;
  flex-direction: column;
justify-content: center;
gap: 32px;
  /* gap: 8vh; */
}

.nav-section{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.nav-section :nth-last-child(1) > .nav-link-title {
  /* padding-top: 20px; */
  /* padding-bottom: 40px; */
  border: none !important;
}

.nav-link {
  box-sizing: border-box;
  /* display: flex; */
  transform: translateY(0px);
 will-change: transform, opacity;
 overflow: hidden;
 width: 40%;
}

.nav-subtitle {
 

  font-size: 16px;
    font-weight: 500;
    color: #8D8F9A;
    margin: 0;
    padding-bottom: 8px;
    
}

.nav-link-title {

    font-weight: 200;
    color: #050505;
    margin: 0;
    text-transform: capitalize;
}
.nav-link-title.sm {

  font-size: 18px;
  padding-top: 2.2vh;
  padding-bottom: 1vh;
}

.nav-link-title.md {

  font-size: 4vw;
  padding-top: 2.2vh;
  padding-bottom: 1vh;
}
.nav-link-title.lg {

  /* font-size: 24px; */
  font-size: 6vw;
  padding-top: 2.4vh;
  padding-bottom: 2.4vh;
  /* border-bottom: 1px solid #f4f4f4 ; */
  
  
}
.underline {
  width: 100%;
  height: 2px;
  background: #050505;
}
.nav-footer {
position: absolute;
width: 80%;
bottom: 0;
padding: 30px 10vw 40px;
margin-left: -10vw;
border-top: #ededed .8px solid ;
will-change: opacity;

}
.nav-footer .nav-link-title {
  font-weight: 300;
  color: #050505;
  margin: 0;
  text-transform: lowercase;
  
}


}