@media only screen and (max-width: 768px) {



#projects {
 box-sizing: border-box;
 background: #fff;
 height: 100%;
 min-height: 100vh;
}
.title-section {
  /* height: 20%; */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  justify-content: space-between; 

}
#projects .title-section {
  position: relative;
  box-sizing: content-box;
  padding: 80px 10vw 60px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  gap: 80px;
justify-content: center;
min-height: 20vh;
background: #fff;

}

#projects .main-title {
  /* padding-bottom:80px; */
}

#projects .line {
  /* font-size: 38px; */
  font-size: 46px;
  color: #050505;
  margin-bottom: 40px;
}
.underline {
  width: 20%;
  background: #202020;
  height: 1px;

}

#projects .main-subtitle {
  
  color: #8D8F9A;
}
#projects .main-text {
  color: #8D8F9A;
  font-size: 16px;
}


.project-list {
  box-sizing: border-box;
  /* display: grid;
  grid-template-columns: auto auto; */
  display: flex; 
  flex-direction: column;
  /* padding:  0px 0vw 60px; */
  
  gap: 12vw;
  padding: 30px 7vw 40px;
}
.project {
  box-sizing: border-box;
  width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: flex-end;

 overflow: hidden;


}

.project-img-c {
position: relative;
  min-width: 100%;
  height: 35vh;
  object-fit: contain;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  overflow: hidden;
  border-radius: 12px;

}
.project-img {
  position: absolute;
  /* height: 150%; */
  height: 100%;
  min-width: 100%;
 
  /* max-height: 100%; */
  /* padding-bottom:20%; */
  /* object-position: -100vw 0; */

}

/* .tag {
  position: absolute;
  box-sizing: content-box;
  padding:  0 12px;
  background: white;
  z-index: 1;
  border-radius: 60px;
  top: 20px;
  left: 20px;

}
.tag-text {
  box-sizing: content-box;

  color: #050505;
  margin: 0;


} */

.project-text-c {
  position: relative;
  width:calc(100% -80px);
  display: flex;
  flex-direction: row;
  /* padding: 40px 40px 30px; */
  padding: 40px 0px;

  overflow: hidden;
  /* background: #181818; */
}

.project .col {
  display: flex;
  flex-direction: column;
  gap: 18px;
  flex: 1 1;
}
.project-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  gap: 24px;
}
#projects .arrow {
  width: 68px;
  height: 68px;
  transform: scale(.8) rotate(-90deg);
  /* flex: 1 1; */
  /* align-self:flex-end; */
  margin-left: -10px;
}
.project-title {
font-family: Inter;
font-size: 24px;
font-weight: 300;
margin: 0;
color: #050505;
line-height: 1.6;
width: 100%;
}
.project-subtitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  color: #050505;
  opacity: .4;
  }


}