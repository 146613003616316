@media only screen and (min-width: 768px) {

/* #about {
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  background-color: #050505;
} */
#about {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #050505;
  
}
#about::-webkit-scrollbar {
  display: none;

}


/* PAGE */

#about .page-c {
  position: absolute;
  box-sizing: content-box;
  /* padding-left: 12vw;
  padding-right: 12vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 12vh; */
  width: 100%;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform;
}
#about .page-section {
  position: relative;
  box-sizing: content-box;
  padding-left: 12vw;
  padding-right: 12vw;

  margin-bottom: 8vh;
  margin-top: 8vh;
  -webkit-font-smoothing: antialiased;
}



#about .left-panel {
  position: relative;
  min-width: 15%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 8%;
  padding-right: 8%;
  flex: 0.5;
}
#about .right-panel {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  background: #252525;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}





.page-section .section-title {
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 8vh;
  -webkit-font-smoothing: antialiased;
}

#about h3 {
  font-family: Inter;
  color: white;
  font-size: 4vw;
  margin: 0;
  font-weight: 300;
  white-space: wrap;
  width: 80%;
  -webkit-font-smoothing: antialiased;
}

.section-text-c {
  display: flex;
  flex-direction: column;
}
.text-row {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  padding-bottom: 12vw;
  padding-top: 8vw;
  will-change: opacity;
  }



.button {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 22%;
  justify-content: space-between;
}
#about .button-text {
  font-family: Inter;
  color: #fff;
  font-weight: 300;
  margin: 0;
  font-size: 1.4vw;
  line-height: 1.6;
  max-width: 32vw;
align-self: center;

  white-space: wrap;
  -webkit-font-smoothing: antialiased;
}
}