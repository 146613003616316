@media only screen and (min-width: 768px) {

 .page-section.banner {

  box-sizing: content-box;
  height: 100vh;
  padding: 0 !important;
  margin-bottom: 0;
  /* background: #050505; */
}
 .banner-img-clip {
  width: 100vw;
  height: 100vh;
  -webkit-clip-path: inset(0px 7vw);
  clip-path: inset(0px 7vw);
  will-change: clip-path, -webkit-clip-path;
}
 .banner-img-c {
  height: 100%;

  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
 .banner-img {
  position: absolute;
  /* height: 150%; */
  min-width: 100%;
  object-position: 50% 50%;
  /* aspect-ratio: auto 1432 / 588; */
  transform: translate3d(0px, 0px, 0px);
  will-change: transform;
}
}