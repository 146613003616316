@media only screen and (max-width: 768px) {

#project {
  /* min-height: 100vh; */
  background: #fff;
  box-sizing: content-box;
  /* opacity: 0; */
}
#project .line {
  font-size: 32px;
  color: #050505;
  margin-bottom: 20px;
  font-weight: 300;
}
#project .main-subtitle {
  color: #8D8F9A;
}
#project .main-text {
  color: #8D8F9A;
}
#project .title-section{
  position: relative;
    box-sizing: content-box;
    padding: 100px 10vw 60px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    gap: 40px;
  justify-content: center;
  min-height: 40vh;
  align-items: flex-start;
}
.downbtn {
  transform: scale(.9);
  margin-left: -4px;
  /* padding-bottom:20px; */
box-sizing: border-box;
/* width: 66px;
height: 66px; */

}
 #project .project-row {
  gap: 24px;
 }

 #project .index-c {
  position:relative;
  
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex: 1 2 0;
  /* justify-content: space-between; */
  gap: 12px;
  align-items: center;

}

#project .index {
  position:relative;
  height: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#project .index-line {
  background: #707070;
  width: 100%;
  height: 1.4px;
}


.page-section.end {
  padding-top: 20px;
}
}